import api from '../lib/api.lib'
import { setIsCampaignLisitingFetching } from './util/campaignListing.action';
import {
    CAMPAIGNS_DATA,
    CAMPAIGN,
    CAMPAIGNS_DATA_PAGINATED,
    CAMPAIGN_PROGRESS_TRAININGS_LIST,
    CAMPAIGN_PROGRESS_TRAINING_FETCH_STATUS,
    CAMPAIGN_PROGRESS_TRAINING_UPDATE_STATUS,
    CAMPAIGN_PROGRESS_ENGAGEMENTS_LIST,
    CAMPAIGN_PROGRESS_ENGAGEMENT_FETCH_STATUS,
    CAMPAIGN_PROGRESS_ENGAGEMENT_UPDATE_STATUS,
    CAMPAIGN_PROGRESS_ENGAGEMENT_GRAPH_STATUS,
    CAMPAIGN_PROGRESS_TRAINING_GRAPH_STATUS,
    CAMPAIGN_PROGRESS_TRAINING_GRAPH_DATA,
    CAMPAIGN_PROGRESS_ENGAGEMENT_GRAPH_DATA,
    CAMPAIGN_PROGRESS_DELETE_STATUS,
    CAMPAIGN_PROGRESS_DELETE_ITEM,
    ALL_CAMPAIGN_PROGRESS,
    ALL_CAMPAIGN_PROGRESS_VIEW,
    CAMPAIGNS,
    CAMPAIGN_FILTER
} from './util/types.action';

const getAllCampaignsData = (data) => ({
    type: CAMPAIGNS_DATA,
    payload: data
})

const getPaginatedCampaignsData = (data) => ({
    type: CAMPAIGNS_DATA_PAGINATED,
    payload: data
})

export const setCampaign = (selectedCampaign) => ({
    type: CAMPAIGN,
    payload: selectedCampaign
})

export const setCampaigns = (campaignsSelected) => ({
    type: CAMPAIGNS,
    payload: campaignsSelected
})

const getCampaignData = (data) => ({
    type: CAMPAIGN,
    payload: data
})

export const setIsViewAllCampaignOpened = (value) => async (dispatch) => {
    await dispatch({ type: ALL_CAMPAIGN_PROGRESS_VIEW, payload: value })
}

export const setSelectedCampaignsFilter = (value) => async (dispatch) => {
    await dispatch({type: CAMPAIGN_FILTER, payload: value})
}

export const GetCampaigns = ({ pageIndex, pageSize, countryCode, campaignIds, organizationPartnerId, userRole }) => async (dispatch) => {
    dispatch(setIsCampaignLisitingFetching(true))
    const region = countryCode === "IDN" ? "IDN" : "IND"
    const params = { region, userRole, country: countryCode, pageIndex: pageIndex, pageSize: pageSize, campaignIds, organizationPartnerId }
    try {
        const res = await api.get(`${process.env.REACT_APP_API}/campaign`, {
            params: params
        });
        dispatch(setIsCampaignLisitingFetching(false))
        if (res.status === 200) {
            if(pageSize){
                await dispatch(getPaginatedCampaignsData(res.data.data))
            } else {
                await dispatch(getAllCampaignsData(res.data.data))
            }

            if (!pageSize && res.data?.data?.rows?.length > 0){
                await dispatch(setCampaign(res.data?.data?.rows[0]))
            }
            if (!pageSize && res.data?.data?.rows?.length === 0) {
                await dispatch(setCampaign())
            }

        }
    } catch (error) {
    }
}

export const GetCampaignById = (id, userRole, userCountry) => async (dispatch) => {
    try {
        const res = await api.get(`${process.env.REACT_APP_API}/campaign/${id}`, {
            params: { userRole, region: userCountry === 'idn' ? 'IDN' : 'IND' }
        });
        if (res.status === 200) {
            await dispatch(getCampaignData(res.data.data))

        }
    } catch (error) {
    }
}

export const UpdateCampaignStatusToComplete = ({ countryCode, campaignId, userCountry, pageIndex, pageSize }) => async (dispatch) => {
    const region = countryCode === "IDN" ? "IDN" : "IND"
    try {
        const res = await api.put(`${process.env.REACT_APP_API}/campaign/${region}/${campaignId}`);
        if (res.status === 200) {
            await dispatch( GetCampaigns({ pageIndex, pageSize, countryCode: userCountry, userRole: '' }))
        }
    } catch (error) {
    }
}

export const getCampaignProgress = (campaignId, filterGroup, progressType, userRole) => async (dispatch) => {
    const statusType = progressType === 'training' ? CAMPAIGN_PROGRESS_TRAINING_FETCH_STATUS : CAMPAIGN_PROGRESS_ENGAGEMENT_FETCH_STATUS
    try {
        await dispatch({ type: statusType, payload: 'requesting' })
        let res = null
        if(filterGroup === 'datePosted'){
            res = await api.get(`${process.env.REACT_APP_API}/campaign/progress/${campaignId}/${progressType}`, {
                params: { userRole}
            });
        } else if (filterGroup === 'monthlyAggregate'){
            res = await api.get(`${process.env.REACT_APP_API}/campaign/progress-cumulative/${campaignId}/${progressType}`, {
                params: {userRole}
            });
        }
        if (res?.status === 200) {
            await dispatch({ type: progressType === 'training' ? CAMPAIGN_PROGRESS_TRAININGS_LIST : CAMPAIGN_PROGRESS_ENGAGEMENTS_LIST, payload: res.data })
            await dispatch({ type: statusType, payload: 'success' })
            await dispatch({ type: statusType, payload: ''})
        }
    } catch (error) {
        await dispatch({ type: statusType, payload: 'error' })
        await dispatch({ type: statusType, payload: '' })
    }
}

export const updateCampaignProgress = (payload, filterGroup) => async (dispatch) => {
    const { campaignId, progressType } = payload
    const statusType = progressType === 'training' ? CAMPAIGN_PROGRESS_TRAINING_UPDATE_STATUS : CAMPAIGN_PROGRESS_ENGAGEMENT_UPDATE_STATUS
    try {
        await dispatch({ type: statusType, payload: { status: 'requesting' } })
        const res = await api.post(`${process.env.REACT_APP_API}/campaign/progress/${campaignId}/${progressType}`, payload);
        if (res.status === 200) {
            if(res.data.success === true){
                await dispatch(getCampaignProgress(campaignId, filterGroup, progressType))
                await dispatch({ type: statusType, payload: { status: 'success', message: res.data.message } })
                await dispatch({ type: statusType, payload: { status: '' } })
            } else {
                await dispatch({ type: statusType, payload: { status: 'error', message: res.data.message } })
                await dispatch({ type: statusType, payload: { status: '' } })
            }   
        }
    } catch (error) {
        await dispatch({ type: statusType, payload: { status: 'error' } })
        await dispatch({ type: statusType, payload: { status: '' } })
    }
}

// export const getCampaignProgressAggregate = (campaignId, progressType, maxRecords) => async (dispatch) => {
//     const statusType = progressType === 'training' ? CAMPAIGN_PROGRESS_TRAINING_GRAPH_STATUS : CAMPAIGN_PROGRESS_ENGAGEMENT_GRAPH_STATUS
//     try {
//         await dispatch({ type: statusType, payload: 'requesting' })
//         const res = await api.get(`${process.env.REACT_APP_API}/campaign/progress-cumulative/${campaignId}/${progressType}?maxRecords=${maxRecords}`);
//         if (res.status === 200) {
//             await dispatch({ type: progressType === 'training' ? CAMPAIGN_PROGRESS_TRAINING_GRAPH_DATA : CAMPAIGN_PROGRESS_ENGAGEMENT_GRAPH_DATA, payload: res.data })
//             await dispatch({ type: statusType, payload: 'success' })
//             await dispatch({ type: statusType, payload: ''})
//         }
//     } catch (error) {
//         await dispatch({ type: statusType, payload: 'error' })
//         await dispatch({ type: statusType, payload: '' })
//     }
// }

export const deleteProgress = progress => async (dispatch) => {
    try {
        await dispatch({ type: CAMPAIGN_PROGRESS_DELETE_STATUS, payload: 'requesting' })
        const res = await api.delete(`${process.env.REACT_APP_API}/campaign/progress/manage/${progress.progressId}`);
        if (res.status === 200) {
            await dispatch({ type: CAMPAIGN_PROGRESS_DELETE_ITEM, payload: progress })
            await dispatch({ type: CAMPAIGN_PROGRESS_DELETE_STATUS, payload: 'success' })
            await dispatch({ type: CAMPAIGN_PROGRESS_DELETE_STATUS, payload: ''})
        }
    } catch (error) {
        await dispatch({ type: CAMPAIGN_PROGRESS_DELETE_STATUS, payload: 'error' })
        await dispatch({ type: CAMPAIGN_PROGRESS_DELETE_STATUS, payload: '' })
    }
}

export const getAllCampaignProgress = (campaignsSelected, progressTypes, userRole, user) => async (dispatch) => {
    try {
        const campaignIds = []
        for (const obj of campaignsSelected ) {
            campaignIds.push({ _id: obj._id, targetEngagement: obj.targetEngagements.target, 
                targetResidents: obj.targetResidents !== 0 ? obj.targetResidents : obj.targetEngagements.target,
                targetTraining: obj.targetTrainings.target })
        }
        const payload = {
            campaignIds, progressTypes, userRole, region: user.country.iso3 === 'idn' ? 'IDN' : 'IND'
        }
        const res = await api.post(`${process.env.REACT_APP_API}/campaign/progress-cumulative-all/`, payload);
        if (res.status === 200) {
            const graphData = await getGraphData(res)
            await dispatch({ type: ALL_CAMPAIGN_PROGRESS, payload: {campaignProgress: res.data, graphData }})
        }
    } catch (error) {
        console.log(error)
    }
}

async function getGraphData(res) {
    const monthEngagement = []
    const progressEngagement = []
    const monthTraining = []
    const progressTraining = []
    res.data.engagement.map((object) => {
        object.monthWiseData.map((monthData) => {
            if(!monthEngagement.includes(monthData._id)){
                monthEngagement.push(monthData._id)
                progressEngagement.push(monthData.progress)
            } else {
                progressEngagement[monthEngagement.indexOf(monthData._id)] += monthData.progress
            }
            return monthData
        })
        return object
    })

    res.data.training.map((object) => {
        object.monthWiseData.map((monthData) => {
            if(!monthTraining.includes(monthData._id)){
                monthTraining.push(monthData._id)
                progressTraining.push(monthData.progress)
            } else {
                progressTraining[monthTraining.indexOf(monthData._id)] += monthData.progress
            }
            return monthData
        })
        return object
    })
    const graphData = {engagement: [], training: []}
    monthEngagement.map((obj, index)=> {
        graphData.engagement.push({_id: obj, progress: progressEngagement[index]})
        return obj
    })
    monthTraining.map((obj, index)=> {
        graphData.training.push({_id: obj, progress: progressTraining[index]})
        return obj
    })
    return graphData
}
