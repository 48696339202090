import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import ProgressBarWithTarget from "./ProgressBarWithTarget";
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EngagementSection = ({ graphData, campaignProgress, campaignsSelected, user }) => {

  const [overallEngagementProgress, setOverallEngagementProgress] = useState(0);
  const [targetEngagement, setTargetEngagement] = useState(0);
  const [overallTrainingProgress, setOverallTrainingProgress] = useState(0);
  const [targetTraining, setTargetTraining] = useState(0);
  const navigate = useNavigate()

  useEffect(()=> {
    let overallProgress = 0
    let target = 0
    for (const obj of campaignProgress.engagement) {
        overallProgress += obj.overallProgress
        target += obj.targetEngagement
    }
    setTargetEngagement(target)
    setOverallEngagementProgress(overallProgress)
    overallProgress = 0
    target = 0
    for (const obj of campaignProgress.training) {
        overallProgress += obj.overallProgress
        target += obj.targetTraining
    }
    setTargetTraining(target)
    setOverallTrainingProgress(overallProgress)
  }, [setTargetEngagement, setOverallEngagementProgress, setTargetTraining, setOverallTrainingProgress, campaignProgress])

  const gotoExpandCurrentCampaign = () => {
    navigate('/expand-campaign')
  }

  return (
    <>
      <div className="d-flex justify-content-center justify-content-md-end h-40px">
        {(user.userRole.includes("app-external-user") && campaignsSelected.length === 1) && (
          <Button variant="primary" onClick={() => { gotoExpandCurrentCampaign() }}size="sm">
            Expand Current Campaign
          </Button>
        )}
      </div>
      <div className="row pt-3">
        <div className="col-12 col-md-6">
          <div className="bg-white border rounded-3 p-3">
            <strong>No. of engagements</strong>
            <div className="mt-2">
              <span className="fs-1 fw-500">{overallEngagementProgress.toLocaleString('en-US') || 0}</span>
              <span className="text-muted ms-1">/{targetEngagement.toLocaleString('en-US') || 0}</span>
            </div>
            <ProgressBarWithTarget
              now={overallEngagementProgress || 0}
              max={targetEngagement || 0}
              color="#00E6B5"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="bg-white border rounded-3 p-3">
            <strong>No. of Agents trained</strong>
            <div className="mt-2">
              <span className="fs-1 fw-500">{overallTrainingProgress.toLocaleString('en-US') || 0}</span>
              <span className="text-muted ms-1">/{targetTraining.toLocaleString('en-US') || 0}</span>
            </div>
            <ProgressBarWithTarget
              now={overallTrainingProgress}
              max={targetTraining}
              color="#00E6B5"
            />
          </div>
        </div>
        <div className="col-12 mt-4">
          <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="border-b-0">
                Engagements, by month
                {campaignsSelected.length === 1 ?
                  <Link
                    to={`/campaign-progress/${campaignsSelected[0]._id}#engagement`}
                    className="text-decoration-none ms-2 fw-500" >
                    <small>View Details</small>
                  </Link>
                : <></>}
              </Accordion.Header>
              <Accordion.Body>
                <div className="my-4 text-center" hidden={graphData.engagement.length > 0}>No data found!</div>
                <Bar
                  hidden={graphData.engagement.length === 0}
                  data={{
                    labels: graphData.engagement.map(p => dayjs(`${p._id}-01`).format('MMM')),
                    datasets: [
                      {
                        label: "Training",
                        data: graphData.engagement.map(p => p.progress),
                        backgroundColor: ["#AB218F"],
                      }
                    ],
                  }}
                  options={{
                    hover: {
                      mode: null,
                    },
                    layout: {
                      padding: {
                        top: 30,
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: false,
                      },
                    },
                    scales: {
                      x: {
                        display: true,
                        grid: {
                          display: false,
                        },
                        ticks: {
                          font: {
                            size: 14,
                            family: '"Montserrat", sans-serif',
                            weight: 700,
                          },
                          color: 'black'
                        }
                      },
                      y: {
                        display: false,
                        beginAtZero: true,
                        grid: {
                          display: false,
                        },
                      },
                    },
                    animation: {
                      onComplete: function (chartInstance) {
                        var chart = chartInstance.chart;
                        const ctx = chart.ctx;

                        ctx.font = "bold 9pt Montserrat";
                        ctx.textAlign = "center";
                        ctx.textBaseline = "bottom";

                        this.data.datasets.forEach(function (dataset, i) {
                          var meta = chart.getDatasetMeta(i);
                          meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data, bar.x, bar.y - 5);
                          });
                        });
                      },
                    },
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey='1'>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="bg-white">
                Agents trained, by month
                {campaignsSelected.length === 1 ?
                  <Link
                    to={`/campaign-progress/${campaignsSelected[0]._id}#training`}
                    className="text-decoration-none ms-2 fw-500" >
                    <small>View Details</small>
                  </Link>
                : <></>}
              </Accordion.Header>
              <Accordion.Body>
                <div className="my-4 text-center" hidden={graphData.training.length > 0}>No data found!</div>
                <Bar
                  hidden={graphData.training.length === 0}
                  data={{
                    labels: graphData.training.map(p => dayjs(`${p._id}-01`).format('MMM')),
                    datasets: [
                      {
                        label: "Training",
                        data: graphData.training.map(p => p.progress),
                        backgroundColor: ["#AB218F"],
                      }
                    ],
                  }}
                  options={{
                    hover: {
                      mode: null,
                    },
                    layout: {
                      padding: {
                        top: 30,
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: false,
                      },
                    },
                    scales: {
                      x: {
                        display: true,
                        grid: {
                          display: false,
                        },
                        ticks: {
                          font: {
                            size: 14,
                            family: '"Montserrat", sans-serif',
                            weight: 700,
                          },
                          color: 'black'
                        }
                      },
                      y: {
                        display: false,
                        beginAtZero: true,
                        grid: {
                          display: false,
                        },
                      },
                    },
                    animation: {
                      onComplete: function (chartInstance) {
                        var chart = chartInstance.chart;
                        const ctx = chart.ctx;

                        ctx.font = "bold 9pt Montserrat";
                        ctx.textAlign = "center";
                        ctx.textBaseline = "bottom";

                        this.data.datasets.forEach(function (dataset, i) {
                          var meta = chart.getDatasetMeta(i);
                          meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data, bar.x, bar.y - 5);
                          });
                        });
                      },
                    },
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

EngagementSection.propTypes = {
  campaignProgress: PropTypes.object.isRequired,
  campaignsSelected: PropTypes.array.isRequired,
  graphData: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  campaignsSelected: state.campaigns.campaignsSelected,
  campaignProgress: state.campaigns.campaignProgress,
  graphData: state.campaigns.graphData
})

export default connect(mapStateToProps, { })(EngagementSection)
