import React, { useState, useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { FiPlus, FiUpload } from "react-icons/fi";
import BlogPost from "./BlogPost";
import PropTypes from 'prop-types';
import { photoModal } from "actions/util/photoModal.action";
import { 
  getBlogPostListByCampaignIds, blogPostModal, setDateFilter,setCountryFilter 
} from 'actions/blogPost.action'
import { connect } from 'react-redux'
import { setCampaigns, setSelectedCampaignsFilter } from '../../actions/campaign.action'
import Loading from "components/util/Loading";
import { setIsViewAllCampaignOpened } from 'actions/campaign.action'
import { MultiSelect } from "react-multi-select-component"
import { setGalleryData } from 'actions/galleryPosts.action'
import { getAllCampaignProgress } from '../../actions/campaign.action'
import { IoChevronDownSharp } from "react-icons/io5";
import DropdownCaret from "components/common/DropdownCaret";

const BlogSection = ({
  isShowBlogPostModal, setDateFilter, blogPostModal, isMonthFilterApplied, blogPostListData, setGalleryData,
  photoModal, user, userCountry, isCampaignAdminWrite, setCountryFilter, selectedCountryFilter,
  CampaignsData, setCampaigns, isFromCampaignLisitingScreen, setIsViewAllCampaignOpened, blogPostListIsLoading,
  campaignsSelected, getBlogPostListByCampaignIds, setSelectedCampaignsFilter, selectedCampaignsFilter,
  isAllCampaignProgressView, isCountryChanged, getAllCampaignProgress, userRole
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [description, setDescription] = useState('')
  const options = [{label: 'Select All', value: 'all'}]

  useEffect(() => {
    if (isFromCampaignLisitingScreen) {
      options.push(selectedCampaignsFilter[0])
    } else if (isCountryChanged) {
      CampaignsData.map((obj) => {
        if(selectedCountryFilter === obj.countryISO) {
          options.push({
            label: obj.name,
            value: obj._id
          })
        }
        return obj
      })

      if(options.length > 1) {
        const campaignsSelectedFromDropdown = []
        setSelectedCampaignsFilter([options[1]])
        setIsViewAllCampaignOpened(false)
        for (const object of CampaignsData) {
          if(options[1].value === object._id) {
            if(selectedCountryFilter === object.countryISO) {
              campaignsSelectedFromDropdown.push(object)
            }
          }
        }
        setCampaigns(campaignsSelectedFromDropdown)
      } else {
        setSelectedCampaignsFilter([])
        setIsViewAllCampaignOpened(false)
        setCampaigns([])
      }
      setCountryFilter({ selectedCountryFilter: selectedCountryFilter, isCountryChanged: false })
    } else {
      CampaignsData.map((obj) => {
        if(selectedCountryFilter === obj.countryISO) {
          options.push({
            label: obj.name,
            value: obj._id
          })
        }
        return obj
      })
      const campaignsSelectedFromDropdown = []
      if(options.length > 1) {
        for (const object of CampaignsData) {
          if(options[1].value === object._id) {
            campaignsSelectedFromDropdown.push(object)
          }
        }
      }
      if(campaignsSelectedFromDropdown.length > 0 || campaignsSelected.length > 0) {
        setCampaigns(campaignsSelected.length > 0 ? campaignsSelected : campaignsSelectedFromDropdown)
      }
      if(selectedCampaignsFilter.length > 0) {
        setSelectedCampaignsFilter(selectedCampaignsFilter)
      } else {
        if(options.length > 0 && options[1] !== undefined) {
          setSelectedCampaignsFilter([options[1]])
        }
      }
    }
  }, [isFromCampaignLisitingScreen, options, setCampaigns, setIsViewAllCampaignOpened, 
    setSelectedCampaignsFilter, isCountryChanged, selectedCampaignsFilter, CampaignsData, 
    selectedCountryFilter, setCountryFilter])

  useEffect(() => {
    const campaignIds = []
    for (const obj of campaignsSelected){
      campaignIds.push(obj._id)
    }
    getBlogPostListByCampaignIds({ isoCountry: selectedCountryFilter, ids: campaignIds, userRole, isMonthFilterApplied })
  }, [isMonthFilterApplied, campaignsSelected, getBlogPostListByCampaignIds, isFromCampaignLisitingScreen, userRole, selectedCountryFilter])

  useEffect(() => {
    getAllCampaignProgress(campaignsSelected, ['engagement', 'training'], userRole, user)
  }, [getAllCampaignProgress, campaignsSelected, userRole, user])

  const openCreateModal = () => {
    setSelectedFiles([])
    setDescription('')
    blogPostModal({ isShowBlogPostModal: true })
  }

  const closeCreateModal = () => {
    blogPostModal({ isShowBlogPostModal: false })
  }

  const onFileSelected = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFiles(e.target.files);
    } else {
      setSelectedFiles([])
    }
  };

  const onDescriptionChange = (e) => {
    if (e.target.value) {
      setDescription(e.target.value);
    } else {
      setDescription('');
    }
  }

  const handleOnCampaignChange = (e) => {
    const campaignsSelectedFromDropdown = []
    let isAllValueSelected = false
    for (const obj of e) {
      if (obj.value === 'all') {
        isAllValueSelected = true
      }
    }
    if (isAllValueSelected){
      setSelectedCampaignsFilter(options)
      setIsViewAllCampaignOpened(true)
      for (const obj of CampaignsData) {
        if(selectedCountryFilter === obj.countryISO) {
          campaignsSelectedFromDropdown.push(obj)
        }
      }
    } else {
      if(e.length === (options.length-1) && isAllCampaignProgressView && options.length > 1) {
        setSelectedCampaignsFilter([options[1]])
        setIsViewAllCampaignOpened(false)
        for (const object of CampaignsData) {
          if(options[1].value === object._id) {
            if(selectedCountryFilter === object.countryISO) {
              campaignsSelectedFromDropdown.push(object)
            }
          }
        }
      } else {
        setIsViewAllCampaignOpened(false)
        setSelectedCampaignsFilter(e)
        for (const object of CampaignsData) {
          for (const obj of e) {
            if(obj.value === object._id) {
              if(selectedCountryFilter === object.countryISO) {
                campaignsSelectedFromDropdown.push(object)
              }
            }
          }
        }
      }
    }
    setGalleryData({
      data: [],
      dataProcessed: [],
      pagination: {
        currentPage: 0,
        totalPages: 1,
        totalRecords: 0
      }
    })
    setCampaigns(campaignsSelectedFromDropdown)
  }

  function onCountryChange(countryObj) {
    setCountryFilter({ selectedCountryFilter: countryObj.code, isCountryChanged: true })
  }

  function displayPhotoModal(description, user) {
    const object = {
      post:{
        _id: 1,
        url: URL.createObjectURL(selectedFiles[0]),
        type: 'image',
        description: description,
        createdBy: user.firstName + ' ' + user.lastName,
        dateCreated: new Date(),
        profileUrl: user.profilePic,
        profilePicUrl: user.profilePicUrl,
        country: selectedCountryFilter,
        files: [...selectedFiles]
      }
    }
    photoModal({ isShowPhotoModal: true, object: object, isPreviewOption: true, selectedIndex: 0 })
  }

  return (
    <>
      <div className="d-flex align-items-top justify-content-between h-40px">
        <div className="d-flex flex-wrap align-items-center w-100">
          <Button onClick={() => { setDateFilter('ALL') } } variant={isMonthFilterApplied === 'ALL' ? "dark" : "light"} size="sm" className="me-1 my-1">
            ALL
          </Button>
          <Button onClick={() => { setDateFilter('YTD') } } variant={isMonthFilterApplied === 'YTD' ? "dark" : "light"} size="sm" className="me-1 my-1">
            YTD
          </Button>
          <Button onClick={() => { setDateFilter('MTH') }} variant={ isMonthFilterApplied === 'MTH' ? "dark" : "light"} size="sm" className="me-1 my-1">
            This month
          </Button>
          <Dropdown className="d-inline-block my-1 country-dropdown">
            <Dropdown.Toggle
              disabled={isFromCampaignLisitingScreen}
              variant="light"
              size="sm"
              className="bg-white border d-flex align-items-center button-label"
            >
              {
                userCountry.filter(countryObject => countryObject.code === selectedCountryFilter).pop().name
              }
              <DropdownCaret color="#aaa" className='ms-2'/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="rounded-4 shadow p-2">
              {userCountry.map((countryObj, i) => (
                <Dropdown.Item className="rounded-3 ps-2 pe-1 fs-sm py-2 mb-1" eventKey={countryObj.code} key={i}
                 onClick={() => { onCountryChange(countryObj) }}>
                  {countryObj.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <MultiSelect
            disabled={isFromCampaignLisitingScreen}
            hasSelectAll={false}
            ClearSelectedIcon={null}
            className='react-multiselect d-inline-block ms-1 my-1 border rounded-4 campaign-dropdown'
            options={options}
            value={selectedCampaignsFilter}
            onChange={handleOnCampaignChange}
            labelledBy="Select Campaign"
          />
        </div>
        <div className="flex-shrink-0">
          {(isCampaignAdminWrite() ||
            user.userRole.includes("insights-super-admin")) && (
              <Button
                variant="primary"
                onClick={openCreateModal}
                className="align-items-center d-inline-flex"
              >
                New Post <FiPlus className="ms-1" />
              </Button>
          )}
        </div>
      </div>
      <div className="pt-3">
        { blogPostListIsLoading || blogPostListData === null
          ? <Loading height="100px" className="my-5"/>
          : (
            blogPostListData.length
            ? blogPostListData.map((object, id) =>
              (<BlogPost blogPostObject={object} key={id} isCampaignAdminWrite={isCampaignAdminWrite} />))
            : <div className="mt-5 mb-3 text-center">No Records Found</div>
          )
        }
      </div>

      <Modal show={isShowBlogPostModal} onHide={closeCreateModal} size="lg" centered>
        <Modal.Body className="px-4 dashboard-modal">
          <div className="d-flex flex-column">
            <h5 className="fw-bold">Add New Feed</h5>
            <div className="text-black">New Post...</div>
            <textarea className="w-100 border rounded-3 p-2 mb-3" placeholder="Describe what is going on now..." rows={6} onChange={onDescriptionChange}></textarea>
            
            <div className="text-black">Media files</div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-6">
                <div className="blog-post-file-upload border rounded-3">
                  <input type="file" accept="image/*, video/mp4, video/quicktime, video/x-msvideo, video/*, .mkv" multiple onChange={onFileSelected} />
                  <span><FiUpload color="#888" size={20} /></span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <div className='footer-actions d-flex'>
            <Button variant="outline-dark" className="flex-1" onClick={closeCreateModal}>Cancel</Button>
            <Button variant="primary" className="flex-1" onClick={() => { displayPhotoModal(description, user) }}>Preview</Button>
          </div>
        </Modal.Footer>
      </Modal>

    </>
  );
};

BlogSection.propTypes = {
  photoModal: PropTypes.func.isRequired,
  CampaignsData: PropTypes.array.isRequired,
  isMonthFilterApplied: PropTypes.string.isRequired,
  blogPostModal: PropTypes.func.isRequired,
  setDateFilter: PropTypes.func.isRequired,
  setCountryFilter: PropTypes.func.isRequired,
  setCampaigns: PropTypes.func.isRequired,
  campaignsSelected: PropTypes.array.isRequired,
  setIsViewAllCampaignOpened: PropTypes.func.isRequired,
  getBlogPostListByCampaignIds: PropTypes.func.isRequired,
  setSelectedCampaignsFilter: PropTypes.func.isRequired,
  selectedCampaignsFilter: PropTypes.array.isRequired,
  setGalleryData: PropTypes.func.isRequired,
  isAllCampaignProgressView: PropTypes.bool.isRequired,
  isCountryChanged: PropTypes.bool.isRequired,
  getAllCampaignProgress: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userCountry: state.auth.userCountry,
  campaignsSelected: state.campaigns.campaignsSelected,
  CampaignsData: state.campaigns.CampaignsData,
  blogPostListData: state.blogPost.blogPostListData,
  blogPostListIsLoading: state.blogPost.blogPostListIsLoading,
  isMonthFilterApplied: state.blogPost.isMonthFilterApplied,
  isShowBlogPostModal: state.blogPost.isShowBlogPostModal,
  isFromCampaignLisitingScreen: state.campaignListing.isFromCampaignLisitingScreen,
  selectedCountryFilter: state.blogPost.selectedCountryFilter,
  selectedCampaignsFilter: state.campaigns.selectedCampaignsFilter,
  isAllCampaignProgressView: state.campaigns.isAllCampaignProgressView,
  isCountryChanged: state.blogPost.isCountryChanged
})

export default connect(mapStateToProps, {
  photoModal, setDateFilter, blogPostModal, setCountryFilter, setCampaigns, setGalleryData,
  setIsViewAllCampaignOpened, getBlogPostListByCampaignIds, setSelectedCampaignsFilter,
  getAllCampaignProgress
})(BlogSection)
